@import 'variables';

$BREAKPOINTS: (
	'small-phone': (
		'min': 0,
		'max': 375px,
	),
	'phone': (
		'min': 0,
		'max': 599px,
	),
	'tablet-portrait': (
		'min': 600px,
		'max': 992px,
	),
	'desktop': (
		'min': 993px,
		'max': 1200px,
	),
	'l-desktop': (
		'min': 1201px,
		'max': 1400px,
	),
	'xl-desktop': (
		'min': 1401px,
		'max': 1600px,
	),
	'full-hd': (
		'min': 1601px,
		'max': 1920px,
	),
);

@mixin screen($breakpoints...) {
	@each $breakpoint in $breakpoints {
		@if map-has-key($BREAKPOINTS, $breakpoint) {
			@media screen and (min-width: map-get($BREAKPOINTS, $breakpoint, 'min')) and (max-width: map-get($BREAKPOINTS, $breakpoint, 'max')) {
				@content;
			}
		} @else {
			@warn "Unfortunately, `#{$BREAKPOINTS}` can not be found. "
                + "Available breakpoints are: #{map-keys($BREAKPOINTS)}.";
		}
	}
}

@mixin only-portrait-screen($breakpoints...) {
	@each $breakpoint in $breakpoints {
		@if map-has-key($BREAKPOINTS, $breakpoint) {
			@media screen and (min-width: map-get($BREAKPOINTS, $breakpoint, 'min')) and (max-width: map-get($BREAKPOINTS, $breakpoint, 'max')) and (orientation: portrait) {
				@content;
			}
		} @else {
			@warn "Unfortunately, `#{$breakpoint}` can not be found. "
                + "Available breakpoints are: #{map-keys($BREAKPOINTS)}.";
		}
	}
}

@mixin only-landscape-screen($breakpoints...) {
	@each $breakpoint in $breakpoints {
		@if map-has-key($BREAKPOINTS, $breakpoint) {
			@media screen and (min-width: map-get($BREAKPOINTS, $breakpoint, 'min')) and (max-width: map-get($BREAKPOINTS, $breakpoint, 'max')) and (orientation: landscape) {
				@content;
			}
		} @else {
			@warn "Unfortunately, `#{$breakpoint}` can not be found. "
                + "Available breakpoints are: #{map-keys($BREAKPOINTS)}.";
		}
	}
}

@mixin only-extra-small-phone-landscape-screen {
	@media only screen and (min-width: 0) and (max-height: 325px) and (orientation: landscape) {
		@content;
	}
}

@mixin only-small-phone-landscape-screen {
	@media only screen and (min-width: 0) and (max-height: 500px) and (orientation: landscape) {
		@content;
	}
}

@mixin only-phone-landscape-screen {
	@media only screen and (min-width: 0) and (max-height: 600px) and (orientation: landscape) {
		@content;
	}
}

@mixin only-desktop-screen {
	@media only screen and (min-width: 1200px) {
		@content;
	}
}

@mixin only-laptop-screen {
	@media only screen and (min-width: 1024px) {
		@content;
	}
}

@mixin only-full-hd-screen {
	@media only screen and (min-width: 1601px) {
		@content;
	}
}

@mixin pixel-ratio-screen($pixel-ratio) {
	@media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: $pixel-ratio) {
		@content;
	}
}

body {
	background: radial-gradient(54.22% 96.3% at 28.16% 50%, $white 0%, $bg-silver 30.5%, $bg-gray 56.6%, $bg-darkgray 80.9%, $icon-gray 100%);
}
