@import 'font';
@import 'variables';
@import 'mixins';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif !important;
	color: $main-text-color !important;

	@include screen('phone', 'tablet-portrait') {
		min-height: calc(100dvh + 1px);
	}
}

* {
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
}

.scrollbar {
	max-width: 100%;
	max-height: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	padding-right: 20px;
}

sup {
	font-size: 65% !important;
}

#tempImg,
#modified {
	display: none;
}

.mat {
	&-slider {
		$p: &; // Variable selector for access;

		&-horizontal &-wrapper {
			left: 0 !important;
			right: 0 !important;
			top: 10px !important;
		}

		&-horizontal {
			.mat-slider-ticks {
				padding-left: 5px;
			}

			&:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb {
				transform: scale(1.19) translate(-1.5px, -4.5px) !important;
			}

			.mat-slider-thumb {
				width: 11px !important;
				height: 11px !important;
				transform: scale(1) translate(-1.5px, -4.5px) !important;
			}
		}

		&-vertical {
			&:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb {
				transform: scale(1.19) translate(-4px, -6.5px) !important;
			}

			.mat-slider-thumb {
				width: 11px !important;
				height: 11px !important;
				transform: scale(1) translate(-4.5px, -6.5px) !important;
			}
		}

		&-track-background {
			background-color: $color-gray-default !important;
		}

		&-min-value:not(.mat-slider-thumb-label-showing) &-thumb {
			border: none !important;
		}

		&-track-fill,
		&-thumb,
		&-thumb-label {
			background-color: $brand-color !important;
		}

		.mat-slider-horizontal &-track-wrapper {
			height: 1px !important;
		}

		.mat-slider-vertical &-track-wrapper {
			width: 1px !important;
		}

		&-disabled {
			#{$p}-track-fill,
			#{$p}-thumb,
			#{$p}-thumb-label {
				background-color: $grey !important;
			}
		}
	}
}

.hidden {
	display: none !important;
}

.mc {
	&-info-icon {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 23px;
		margin-left: 15px;
		cursor: pointer;

		@include mask-svg(icon_info, $default-gray, 24px 24px, center);

		&.active {
			background-color: $brand-color;
		}
	}

	&-info-icon-default {
		white-space: nowrap;

		&:before {
			content: '\A0';
		}

		&:after {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			cursor: pointer;
			vertical-align: middle;
			@include mask-svg(icon-info, $grey, 20px 20px);
		}

		&[aria-describedby]:after {
			background-color: $brand-color;
		}
	}

	&-warn-icon {
		width: 20px;
		height: 20px;
		position: absolute;
		cursor: pointer;
		background-image: url('#{$iconsLinkNew}/icon-warn.svg');
		background-repeat: no-repeat;
		background-position: center;
	}

	&-help-icon {
		white-space: nowrap;

		&:before {
			content: '\A0';
		}

		&:after {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			cursor: pointer;
			@include mask-svg(icon-help-old, $color-gray-default, 16px 16px);
		}

		&[aria-describedby]:after {
			background-color: $brand-color;
		}
	}
}

.mat-dialog-container {
	background: $white;
}

.mc-country-modal,
.mc-blind-modal {
	width: 645px;
	padding: 50px 0;
	margin: 0 auto;
	background: $white;
	border-radius: 6px;
	position: relative;
	box-shadow: $shadow;

	&__bg {
		&,
		.mat-dialog-container {
			background: $country-bg !important;
			overflow: unset;
		}

		&::before,
		&::after {
			position: absolute;
			content: '';
		}

		&::before {
			top: 0;
			width: 100%;
			padding: 10px;
			background: $brand-color;
		}
	}

	&__dialog {
		width: 100vw;

		.mat-dialog-container {
			background: none;
			box-shadow: none;
		}
	}
}

.mc-blind-modal,
.mc-modal {
	&__internal {
		@include screen('phone') {
			position: absolute !important;
			max-width: unset !important;
			bottom: 0;
			width: 100vw !important;
		}

		@include only-phone-landscape-screen() {
			position: absolute !important;
			top: 15px;
		}
	}
}

.mc-blind-modal {
	background: transparent;
	box-shadow: none;
	width: 860px;
	padding: 0;
}

.mc-modal__full-screen .mat-dialog-container {
	border-radius: 0;
}

.mc-frame__item-block {
	&.other {
		.color-picker .popover-arrow .popover-arrow -right {
			top: 47px;
			border-width: 10px 10px;
		}
	}
}

.mc-color__item {
	.color-picker {
		.popover-arrow .popover-arrow -right {
			top: 44px;
			border-width: 10px 10px;
		}
	}
}

.separator {
	width: 100%;
	height: 1px;
	background: $color-gray-default;
	margin: 60px 0;
}

.cdk-global-scrollblock {
	overflow: auto;
}

.cdk-overlay {
	&-container {
		overflow-y: auto;
		pointer-events: all;

		&.internal {
			z-index: 1061;
		}
	}

	&-backdrop {
		position: fixed;
		transition: none;

		&.android + .cdk-global-overlay-wrapper {
			align-items: flex-start !important;
		}
	}

	&-pane {
		@include only-landscape-screen('phone', 'tablet-portrait') {
			&.landscape {
				max-width: 480px !important;
			}
		}
	}
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'responsive';

@import 'simplebar/dist/simplebar.css';

.mc {
	&-tooltip,
	&-popover {
		max-width: 359px;
		width: max-content;
		border-radius: 6px;
		background: $white;
		box-shadow: 0 0 10px -3px rgba($black, 0.4);
	}

	&-popover {
		padding: 10px;
		color: $gray-text;
		font-size: 14px;
		font-weight: 400;
		white-space: normal;
		border: 0;

		&-delete,
		&-copy,
		&-reset {
			width: 244px;

			.popover-body {
				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-wrap: wrap;
			}
		}

		& .popover-arrow {
			width: 20px;
			height: 20px;
			margin: 0;
			overflow: hidden;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 2px;
				width: 10px;
				height: 10px;
				background: $white;
				border-width: 0;
				box-shadow: $shadow;
			}

			&::before {
				content: unset !important;
			}
		}

		& .popover-body {
			padding: 0;
			color: inherit;
		}

		&.bs-popover-top {
			& .popover-arrow {
				overflow: hidden;
				border-color: $white transparent transparent transparent;
				bottom: -15px;

				&::after {
					transform: rotate(45deg);
				}
			}

			&-left {
				margin-left: -40px !important;

				& .popover-arrow {
					transform: translate(25px, 0px) !important;
				}
			}

			&-right {
				margin-left: 40px !important;

				& .popover-arrow {
					transform: translate(-5px, 0px) !important;
				}
			}
		}

		&.bs-popover-bottom {
			.popover-arrow {
				top: -5px;

				&::after {
					left: 4px;
					top: 2px;
					transform: rotate(-45deg);
				}
			}
		}

		&.bs-popover-end {
			.popover-arrow {
				left: -10px;
				margin-top: 4px;

				&::after {
					left: 6px;
					transform: rotate(-45deg);
				}
			}
		}

		&.bs-popover-start {
			.popover-arrow {
				right: -12px;
				margin-top: 4px;

				&::after {
					transform: rotate(-45deg);
				}
			}
		}

		@include screen('phone') {
			max-width: 250px;
		}

		&-card-info {
			z-index: 999;
			padding: 50px;
			width: 100%;
			max-width: 445px;

			& .popover-arrow {
				display: none;
			}

			@include screen('phone') {
				max-width: 340px !important;
				padding: 25px;

				&.bs-popover-top-left {
					margin-left: -51px !important;
				}

				&.map.bs-popover-top-left {
					margin-left: -21px !important;
				}
			}

			@include screen('small-phone') {
				max-width: 320px !important;
			}
		}

		&-blind-info {
			max-width: 275px;

			@include screen('small-phone') {
				max-width: 225px !important;
			}
		}

		&-image-visualisation {
			color: $brand-color;
			padding: 5px 10px;

			&.reset {
				left: 40px !important;

				&.bs-popover-bottom {
					.popover-arrow {
						left: calc(50% - 40px);
					}
				}
			}

			&.save {
				left: -5px !important;

				&.bs-popover-bottom {
					.popover-arrow {
						left: calc(50% + 5px);
					}
				}
			}
		}

		&-color-picker {
			padding: 0;
			z-index: 1001;

			@include screen('small-phone') {
				left: -50px !important;

				.popover-arrow {
					left: calc(50% + 50px) !important;
				}
			}
		}

		&-reset {
			&.bs-popover-top-left {
				.popover-arrow {
					left: 35px;
				}
			}
		}
	}

	&-ar {
		&-viewer {
			position: fixed;
			bottom: 56px;
			right: 60px;
			pointer-events: none;
			z-index: 99999;
			height: 40px;
			visibility: hidden;
			width: 112px;
			border-radius: 100px;

			&.open {
				visibility: visible;
			}

			@mixin core-styles {
				bottom: 10px;
				right: 20px;
				width: 101px;
			}

			@include only-landscape-screen('phone', 'tablet-portrait') {
				@include core-styles();
			}

			@include screen('phone') {
				@include core-styles();
			}

			&.not-compatible {
				bottom: 100px;
				left: 50%;
				transform: translateX(-50%);

				@include only-landscape-screen('phone', 'tablet-portrait') {
					bottom: 30px;
				}
				@include only-landscape-screen('desktop') {
					bottom: 115px;
				}
			}
		}

		&-button {
			position: absolute;
			z-index: 9999999;
			pointer-events: all;

			@include button($width: 112px, $height: 40px, $bg: $yellow, $radius: 100px);
			font-size: 16px;
			line-height: 24px;
			color: $brand-color;
			border: none;

			@mixin core-styles {
				@include button($width: 101px, $height: 40px, $bg: $yellow, $radius: 100px);
				font-size: 14px;
				line-height: 18px;
				color: $brand-color;
				border: none;
			}

			@include screen('phone') {
				@include core-styles();
			}

			@include only-landscape-screen('phone', 'tablet-portrait') {
				@include core-styles();
			}
		}
	}

	&-live-background {
		position: fixed;
		bottom: 0px;
		right: 0px;
		transform: scale(0.0001, 0.0001);
		opacity: 0;
	}
}

.simplebar-track.simplebar-horizontal {
	display: none;
}

app-root {
	display: block;
}
