@import 'variables';
@import 'responsive';

@mixin mask-svg($icon, $color: $brand-color, $size: 40px 40px, $position: 0 0, $iconLink: $iconsLink) {
	mask-image: url('#{$iconLink}/#{$icon}.svg');
	mask-repeat: no-repeat;
	mask-position: $position;
	mask-size: $size;

	// For older browsers fallback
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cuse xlink:href='#{$icon}' fill='%23{$color}'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: $position;
	background-size: $size;
	background-color: $color;

	// Ensure the icon is visible in older browsers
	content: '';
	width: if(type-of($size) == number, $size, nth($size, 1));
	height: if(type-of($size) == number, $size, nth($size, 2));
}

@mixin button(
	$width: auto,
	$height: 30px,
	$bg: $brand-color,
	$font: 14px,
	$color: $white,
	$border: $brand-color,
	$radius: 15px,
	$weight: 400
) {
	width: $width;
	height: $height;
	background: $bg;
	font-size: $font;
	line-height: $height;
	color: $color;
	border-radius: $radius;
	border: 1px solid $border;
	font-weight: $weight;
	padding: 0 20px;
	cursor: pointer;
}

@mixin buttonNew(
	$width: auto,
	$height: auto,
	$bg: $white,
	$bgHover: $brand-color,
	$yellowBtn: false,
	$font: 16px,
	$color: $brand-color,
	$colorHover: $white,
	$border: $brand-color,
	$radius: 100px,
	$weight: 500,
	$padding: 12px 20px,
	$isIcon: false,
	$icon: '',
	$iconColor: $brand-color,
	$iconWidth: 18px,
	$iconHeight: 18px
) {
	@include button($width, $height, $bg, $font, $color, $border, $radius, $weight);

	line-height: 1.5;
	padding: $padding;
	text-transform: capitalize;
	justify-content: center;
	display: flex;
	align-items: center;

	@include only-laptop-screen {
		&:hover {
			background: $bgHover;
			border-color: $bgHover;
			color: $colorHover;

			@if $yellowBtn {
				color: $brand-color;
			}

			@if $isIcon {
				&:after {
					background-color: $colorHover;

					@if $yellowBtn {
						background-color: $brand-color;
					}
				}
			}
		}
	}

	@if $isIcon {
		&:after {
			content: '';
			display: inline-block;
			width: $iconWidth;
			height: $iconHeight;
			margin-left: 13px;

			@include mask-svg($icon, $color: $iconColor, $size: $iconWidth $iconHeight);
		}
	}

	&.disabled,
	&:disabled {
		border-color: $grey;
		color: $grey;
		background: $white;
		cursor: not-allowed;

		&:after {
			background: $grey;
		}
	}

	&.loading {
		position: relative;
		color: $bgHover;
		background-color: $bgHover;
		border-color: $bgHover;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: block;
			width: 40px;
			height: 40px;
			@include mask-svg('spinner', $size: 40px 40px);
			background-color: $white;
		}

		&::after {
			background-color: $bgHover;
		}
	}
}

@mixin yellow-button(
	$width: 386px,
	$height: 48px,
	$font: 18px,
	$position: right,
	$hasIcon: true,
	$weight: 300,

	$hasImg: false,
	$imgWidth: 0,
	$imgHeight: 0,
	$imgBg: inherit,
	$imgSource: '',
	$imgTop: 0,
	$imgRight: 0,
	$imgLeft: 0,
	$bg: $yellow-color
) {
	width: $width;
	height: $height;
	line-height: $height;
	text-align: center;
	border-radius: 30px;
	border: 2px solid transparent;
	background: $bg;
	color: $white;
	font-size: $font;
	font-weight: $weight;
	font-family: $fontFamily;
	position: relative;
	cursor: pointer;
	display: inline-block;
	transition: 0.1s;

	&:hover {
		background: $white;
		color: $bg;
		border: 2px solid $bg;

		@if $hasIcon {
			&::before {
				@include mask-svg(icon_arrow_circle, $bg, 24px 24px);
			}
		}

		@if $hasImg {
			&::before {
				@include mask-svg($imgSource, $bg, $imgWidth $imgHeight);
			}
		}
	}

	@if $width == auto {
		padding: 0 30px;
	}

	@if $hasIcon {
		&::before {
			content: '';
			position: absolute;
			width: 24px;
			height: 24px;
			top: 12px;

			@if $position == right {
				right: 12px;
			} @else if $position == left {
				left: 12px;
				transform: rotate(180deg);
			} @else {
				@error "Unknown direction #{$position}.";
			}

			@include mask-svg(icon_arrow_circle, $white, 24px 24px);
		}
	}

	@if $hasImg {
		&::before {
			content: '';
			position: absolute;
			width: $imgWidth;
			height: $imgHeight;
			top: $imgTop;

			@if $position == right {
				right: $imgRight;
			} @else if $position == left {
				left: $imgLeft;
			} @else {
				@error "Unknown direction #{$position}.";
			}

			@include mask-svg($imgSource, $imgBg, $imgWidth $imgHeight);
		}
	}
}

@mixin blue-button(
	$width: 386px,
	$height: 48px,
	$font: 18px,
	$position: right,
	$hasIcon: true,
	$weight: 300,

	$hasImg: false,
	$imgWidth: 0,
	$imgHeight: 0,
	$imgBg: inherit,
	$imgSource: '',
	$imgTop: 0,
	$imgRight: 0,
	$imgLeft: 0,
	$bg: $brand-color
) {
	@include yellow-button(
		$width: $width,
		$height: $height,
		$font: $font,
		$position: $position,
		$hasIcon: $hasIcon,
		$weight: $weight,
		$hasImg: $hasImg,
		$imgWidth: $imgWidth,
		$imgHeight: $imgHeight,
		$imgBg: $imgBg,
		$imgSource: $imgSource,
		$imgTop: $imgTop,
		$imgRight: $imgRight,
		$imgLeft: $imgLeft,
		$bg: $bg
	);
}

%loaderBlock {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba($white, 0.7);
	border-radius: 30px;

	&::after {
		background: url('#{$iconsLink}/spinner.svg') no-repeat;
		content: '';
		position: absolute;
		top: -5px;
		width: 60px;
		height: 60px;
		background-size: 60px 60px;
		margin-left: -25px;
	}
}

%loader {
	.loader {
		@extend %loaderBlock;
	}

	&.loading {
		.loader {
			display: block;
		}
	}
}

@mixin loading {
	position: relative;

	&::before,
	&::after {
		position: absolute;
		content: '';
	}

	&::before {
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		backdrop-filter: blur(3px);
		background: rgba($white, 0.7);
		z-index: 10;
		border-radius: 10px;
	}

	&::after {
		top: 10%;
		left: 50%;
		z-index: 10;
		width: 100px;
		height: 100px;
		background: url('#{$iconsLink}/spinner.svg') no-repeat center center;
	}
}

@mixin toggle-button($bgActive: $brand-color, $color: $brand-color, $colorActive: $white, $firstButtonIcon: '', $secondButtonIcon: '') {
	position: sticky;
	display: flex;
	flex-direction: column;
	width: 46px;
	height: 90px;
	padding: 2px;
	border-radius: 100px;
	background: $white;
	box-shadow: $shadow;
	gap: 2px;

	&-button {
		position: relative;
		display: flex;
		width: 42px;
		height: 42px;
		border: unset;
		border-radius: 50%;
		background: transparent;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:after {
			display: block;
			width: 100%;
			height: 100%;
			min-width: 24px;
			min-height: 24px;
			content: '';
			transition: 0.2s 0.05s ease-in-out background-color;
		}

		&:before {
			position: absolute;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			transition: 0.2s ease-in-out top;
			border-radius: 50%;
			background: $bgActive;
			z-index: -1;
		}

		&:first-of-type {
			@if $firstButtonIcon != '' {
				&:after {
					@include mask-svg($firstButtonIcon, $color, 24px 24px, center, $iconLink: $iconsLinkNew);
				}
			}

			&:before {
				top: calc(100% + 2px);
			}
		}

		&:last-of-type {
			@if $secondButtonIcon != '' {
				&:after {
					@include mask-svg($secondButtonIcon, $color, 24px 24px, center, $iconLink: $iconsLinkNew);
				}
			}

			&:before {
				top: calc(-100% - 2px);
			}
		}

		&.active {
			&:after {
				background-color: $colorActive;
			}

			&:before {
				top: 0;
				left: 0;
			}
		}
	}
}

@mixin iv-type-button-tablet-screen {
	font-size: 16px;
	line-height: 1.5;
	align-items: center;
	justify-content: flex-start;
	padding: 15px 30px;

	&::before {
		width: 22px;
		height: 22px;
		margin: 0 10px 0 0;
		mask-size: 22px 22px;
	}
}

@mixin iv-type-button($icon, $iconSize: 36px 36px, $folder: $iconsLinkNew) {
	font-size: 26px;
	font-weight: 500;
	line-height: 38px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	max-width: 360px;
	margin-bottom: 0;
	padding: 40px 0;
	color: $brand-color;
	border: none;
	border-radius: 6px;
	outline: none;
	background: $country-bg;

	&::before {
		content: '';
		display: block;
		width: 36px;
		height: 36px;
		margin: 0 20px 0 0;
		@include mask-svg($icon, $brand-color, $size: $iconSize, $position: center, $iconLink: $folder);
	}

	&:hover,
	&.active {
		color: $white;
		background: $brand-color;

		&::before {
			background-color: $white;
		}
	}

	@include screen('phone', 'tablet-portrait') {
		@include iv-type-button-tablet-screen();
	}

	@include screen('phone') {
		max-width: unset;
		padding: 10px 30px;
	}

	@include pixel-ratio-screen(1.25) {
		@include iv-type-button-tablet-screen();
	}
}

@mixin text-overflow-ellipsis() {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
